// @flow
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrivedOrderItem from '../../../../elements/ArrivedOrderItem/ArrivedOrderItem';
import CourierReceptionOrderItemNotes
  from '../../../courier-reception/components/CourierReceptionShipment/CourierReceptionOrderItemNotes';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames/bind';
import {Card, InputAdornment, Tooltip} from '@material-ui/core';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ShoesS from '../../../../assets/img/volumetric-options/shoes/shoe-S.inline.svg';
import ShoesM from '../../../../assets/img/volumetric-options/shoes/shoe-M.inline.svg';
import ShoesL from '../../../../assets/img/volumetric-options/shoes/shoe-L.inline.svg';
import ShoesXL from '../../../../assets/img/volumetric-options/shoes/shoe-XL.inline.svg';
import CameraS from '../../../../assets/img/volumetric-options/cameras/camera-S.inline.svg';
import CameraM from '../../../../assets/img/volumetric-options/cameras/camera-M.inline.svg';
import CameraL from '../../../../assets/img/volumetric-options/cameras/camera-L.inline.svg';
import CameraXL from '../../../../assets/img/volumetric-options/cameras/camera-XL.inline.svg';
import ConsoleS from '../../../../assets/img/volumetric-options/consoles/console-S.inline.svg';
import ConsoleM from '../../../../assets/img/volumetric-options/consoles/console-M.inline.svg';
import ConsoleL from '../../../../assets/img/volumetric-options/consoles/console-L.inline.svg';

import type { OrderItem as OrderItemType, OrderItemCancellation } from '../../../../entities';
import type { HTTPError } from '../../../../common/error';

type Props = {
  +classes: { [key: string]: any };
  +item: OrderItemType;
  +orderState: string;
  +isDiy?: boolean;
  +isLoading?: boolean;
  +executeUpdateOrderItemInvoice?: (orderItemId: number, invoiceFormData: FormData) => void;
  +updateInvoiceError?: HTTPError;
  +executeUpdateOrderItemTracking?: (
    orderItemId: number,
    trackingCourier: string,
    trackingNumber: string,
    trackingUrl?: string,
  ) => void;
  +executeUpdateOrderItemStoreOrderId?: (orderItemId: number, storeOrderId: string) => void;
  +executeReceiveOrderItem?: (orderItemId: number) => void;
};

type State = {
  +invoiceUri?: { [orderItemId: number]: string };
  +trackingCourier?: string;
  +trackingNumber?: string;
  +trackingUrl?: string;
  +storeOrderId?: string;
  +automaticTracking: boolean;
};

const volumeImages = {
  '4': {
    'XS': CameraS,
    'S': CameraM,
    'M': CameraL,
    'L': CameraXL,
  },
  '12': {
    'XS': ShoesS,
    'S': ShoesM,
    'M': ShoesL,
    'L': ShoesXL,
  },
  '16': {
    'S': ConsoleS,
    'M': ConsoleM,
    'L': ConsoleL,
  },
};

const KG_TO_LB_FACTOR = 2.205;

class OrderItem extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const { item } = props;
    this.state = {
      invoiceUri: {},
      storeOrderId: item.tracking ? item.tracking.storeOrderId : '',
      trackingCourier: item.tracking ? item.tracking.trackingCourier : '',
      trackingNumber: item.tracking ? item.tracking.trackingNumber : '',
      trackingUrl: item.tracking ? item.tracking.trackingUrl : '',
      automaticTracking: item.tracking ? !!item.tracking.trackingUrl : false,
    };
  }

  componentDidUpdate(prevProps: Props): * {
    const { updateInvoiceError, item } = this.props;
    const { invoiceUri } = this.state;
    if (
      !!updateInvoiceError &&
      updateInvoiceError.code === 413 &&
      updateInvoiceError.data &&
      !!invoiceUri &&
      Object.keys(invoiceUri).length !== 0
    ) {
      this.setState({ invoiceUri: {} });
    }
    if (prevProps.item.orderItemId !== item.orderItemId) {
      this.setState({
        invoiceUri: {},
        storeOrderId: item.tracking ? item.tracking.storeOrderId : '',
        trackingCourier: item.tracking ? item.tracking.trackingCourier : '',
        trackingNumber: item.tracking ? item.tracking.trackingNumber : '',
        trackingUrl: item.tracking ? item.tracking.trackingUrl : '',
      });
    }
  }

  getVolumetricImage = (categoryId: string, volumeTier: string): string => {
    return volumeImages[categoryId][volumeTier];
  };

  handleChangeInvoice = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { executeUpdateOrderItemInvoice, item } = this.props;
    const { files } = e.target;
    if (files.length && !!executeUpdateOrderItemInvoice) {
      const formData = new FormData();
      const reader = new FileReader();
      formData.append('file', files[0]);
      formData.append('resourceGroup', 'EXTERNAL_INVOICE');
      reader.onload = () => {
        this.setState({
          invoiceUri: { [item.orderItemId]: reader.result.toString() },
        });
      };
      reader.readAsDataURL(files[0]);
      executeUpdateOrderItemInvoice(item.orderItemId, formData);
    }
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (e.target instanceof HTMLInputElement) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    }
  };

  handleOnKeyPress = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { charCode, target } = e;
    const {
      item,
      executeUpdateOrderItemTracking,
      executeUpdateOrderItemStoreOrderId,
    } = this.props;
    if (charCode === 13) {
      if (target instanceof HTMLInputElement) {
        const { value, name } = target;
        const { trackingNumber } = this.state;
        if (name === 'trackingNumber' && executeUpdateOrderItemTracking) {
          executeUpdateOrderItemTracking(item.orderItemId, "OTHER", value);
        } else if (name === 'storeOrderId' && executeUpdateOrderItemStoreOrderId) {
          executeUpdateOrderItemStoreOrderId(item.orderItemId, value);
        } else if (
          name === 'trackingUrl' &&
          executeUpdateOrderItemTracking &&
          trackingNumber
        ) {
          executeUpdateOrderItemTracking(item.orderItemId, "AMZL", trackingNumber, value);
        }
      }
    }
  };

  buildInvoiceProperty = () => {
    const { classes, item, updateInvoiceError } = this.props;
    const { invoiceUri } = this.state;
    let entityTooLargeError = false;
    if (
      !!updateInvoiceError &&
      updateInvoiceError.code === 413 &&
      updateInvoiceError.data &&
      updateInvoiceError.data['orderItemId'] === item.orderItemId
    ) {
      entityTooLargeError = true;
    }
    return (
      <Grid item container alignItems="center">
        {item.externalInvoice ? (
          <a href={item.externalInvoice.image} target="_blank" rel="noopener noreferrer"
             className={classes.uploadProperty}>
            Invoice:
          </a>
        ) : (
          <p className={classes.uploadProperty}>Invoice:</p>
        )}
        <input
          id={`${item.orderItemId}-invoice`}
          type="file"
          onChange={this.handleChangeInvoice}
          style={{ display: 'none' }}
          accept=".jpg, .png, .jpeg, .pdf"
        />
        <label htmlFor={`${item.orderItemId}-invoice`}>
          <IconButton component="span">
            <ReceiptIcon
              className={classNames({
                [classes.invoiceExists]: !!item.externalInvoice,
                [classes.invoiceMissing]: !item.externalInvoice,
                [classes.invoiceError]: entityTooLargeError,
                [classes.invoiceReady]: !!invoiceUri && !!invoiceUri[item.orderItemId],
              })}
            />
          </IconButton>
        </label>
        {entityTooLargeError && <p className={classes.textError}>Invoice too large</p>}
      </Grid>
    );
  };

  handleReceiveOrderItem = (orderItemId: number) => {
    const { executeReceiveOrderItem } = this.props;
    if (!!executeReceiveOrderItem) {
      executeReceiveOrderItem(orderItemId);
    }
  };

  buildCancellationMessage = (cancellations: OrderItemCancellation[]) =>
    cancellations.map((cancellation: OrderItemCancellation, key: number) => {
      const { classes } = this.props;
      let message = cancellation.motive.name;
      if (message === 'OTHER') {
        message = cancellation.message;
      }
      return (
        <div key={key}>
          <p className={classes.cancelled}>CANCELLED</p>
          <p className={classes.cancelled}>{message}</p>
        </div>);
    });

  buildUrl = (url: string) => {
    const u = new URL(url);
    if (URL && url.includes('amazon')) {
      return `${u.origin}${u.pathname}`;
    }
    return url;
  };

  render() {
    const { classes, item, orderState, isLoading } = this.props;
    const { trackingNumber, storeOrderId, trackingUrl, automaticTracking } = this.state;
    const weightLb = (parseFloat(item.weight) * KG_TO_LB_FACTOR).toFixed(2);
    return (
      <Grid item container className={classes.orderItemWrap} style={{background: item.hasArrived ? '#9aea9c' : 'white'}}>
        <Grid item container spacing={8}>
          <Grid item>
            <img className={classes.itemImage} src={item.image} alt="item"/>
          </Grid>
          <Grid item xs>
            <p className={classes.property}>
              Unit Price: <span className={classes.span}>${item.price}</span>
            </p>
            <p className={classes.property}>
              Amount: <span className={classes.span}>{item.amount}</span>
            </p>
            {!!item.weight && (
              <p className={classes.property}>
                Weight:{' '}
                <span className={classes.span}>
                  {item.weight} Kg / {weightLb} Lb
                </span>
              </p>
            )}
            <p className={classes.property}>
              Category: <span className={classes.span}>{item.category ? item.category.name : ''}</span>
            </p>
            <p className={classes.property}>
              Original packaging: <span
              className={classes.span}>{item.originalPackaging ? 'With box' : 'Without box'}</span>
            </p>
            {!!item.volumeTier && !!volumeImages[item.category.id] && (
              <Fragment>
                <p className={classes.property}>
                  Volumetric option: <span className={classes.span}>{item.volumeTier.code}</span>
                </p>
                <img
                  className={classes.itemVolumetricImage}
                  src={this.getVolumetricImage(item.category.id.toString(), item.volumeTier.code)}
                  alt="Qempo"
                />
              </Fragment>
            )}
            {!!item.detail && (
              <p className={classes.property}>
                Details: <span className={classes.span}>{item.detail}</span>
              </p>
            )}
            {item.hasCancellations && this.buildCancellationMessage(item.cancellations)}
          </Grid>
          {(orderState === 'RESERVED' ||
            orderState === 'PURCHASED' ||
            orderState === 'IN_COURIER_FLOW' ||
            orderState === 'AVAILABLE_FOR_COURIER') && (
            <Grid item xs container direction="column" className={classNames({ [classes.loading]: isLoading })}>
              {this.buildInvoiceProperty()}
              <Grid item>
                <TextField
                  label="Order id from store"
                  name="storeOrderId"
                  value={storeOrderId || ''}
                  onChange={this.handleChange}
                  onKeyPress={this.handleOnKeyPress}
                  helperText="Press Enter to save"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Tracking id"
                  name="trackingNumber"
                  value={trackingNumber || ''}
                  onChange={this.handleChange}
                  onKeyPress={this.handleOnKeyPress}
                  helperText="Press Enter to save"
                  InputProps={{
                    startAdornment: trackingNumber ? (
                      <InputAdornment position="start">
                        <Tooltip title={automaticTracking ? 'Automatic' : 'Needs manual reception'}
                                 placement="left-start">
                          {automaticTracking ? (
                            <CheckCircleIcon className={classes.iconSuccess}/>
                          ) : (
                            <NotificationImportantIcon className={classes.iconWarning}/>
                          )}
                        </Tooltip>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Tracking url"
                  name="trackingUrl"
                  value={trackingUrl || ''}
                  onChange={this.handleChange}
                  onKeyPress={this.handleOnKeyPress}
                  helperText="Press Enter to save"
                />
              </Grid>
              {(orderState === 'PURCHASED' || orderState === 'IN_COURIER_FLOW') && item.state === 'PENDING' && (
                <Grid item>
                  <Button
                    className={classes.buttonReceiveOrderItem}
                    color="secondary"
                    variant="contained"
                    onClick={() => this.handleReceiveOrderItem(item.orderItemId)}
                  >
                    CONFIRM RECEPTION USA{' '}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <a href={this.buildUrl(item.url)} target="_blank" rel="noopener noreferrer" className={classes.itemName}>
            {item.name}
          </a>
        </Grid>
        <Grid item xs={12}>
          <ArrivedOrderItem orderItem={item} />
        </Grid>
        <Grid item xs={12}>
          <CourierReceptionOrderItemNotes
            orderItemId={item.orderItemId}
            notes={item.tracking ? item.tracking.notes : ''}
            storeOrderId={item.tracking ? item.tracking.storeOrderId : ''} />
        </Grid>
      </Grid>
    );
  }
}

OrderItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  orderState: PropTypes.string.isRequired,
  isDiy: PropTypes.bool,
  isLoading: PropTypes.bool,
  executeUpdateOrderItemInvoice: PropTypes.func,
  updateInvoiceError: PropTypes.object,
  executeUpdateOrderItemTracking: PropTypes.func,
  executeUpdateOrderItemStoreOrderId: PropTypes.func,
  executeReceiveOrderItem: PropTypes.func,
};

export default withStyles(styles)(OrderItem);
