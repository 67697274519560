// @flow
import { decimalRegex, emailRegex, urlRegex, dateRegex, orderRegex, flightRegex } from '../string';

// import type { Component } from 'react';

// eslint-disable-next-line no-use-before-define
export type FieldValidator = { [key: string]: FieldErrorValidator };
export type FieldErrorValidator = { [key: string]: string | (value: any) => boolean };
export type FormValidationResult = {
  field: string;
  errors: string[];
};
export type FormField = {
  value: any,
  errors?: string[],
};

// export const regexDecimal = /[0-9]{0,}(\.([0-9][0-9])?)/;

// Returns true if error
const includedValidators: { [key: string]: (value: any) => boolean } = {
  emailFormat: (value: string = '') => {
    const r = value.match(emailRegex);
    return !r || r[0] !== value;
  },
  required: (value: any) => !value,
  flightIdFormat: (value: string) => !flightRegex.test(value) && !!value.length,
  orderIdFormat: (value: string) => !orderRegex.test(value) && !!value.length,
  dateFormat: (value: string) => !dateRegex.test(value) && !!value.length,
  urlFormat: (value: string = '') => {
    // If empty then no error
    if (!value) return false;
    const r = value.match(urlRegex);
    return !r || r[0] !== value;
  },
  decimalFormat: (value: string = '') => {
    const r = value.match(decimalRegex);
    return !r || r[0] !== value;
  },
};

function getValidatorFn(validatorValue: string | (value: any) => boolean): (value: any) => boolean {
  if (typeof validatorValue === 'string') {
    const vfn = includedValidators[validatorValue];
    if (!vfn) {
      throw new Error(`Not existent included validator: ${validatorValue}`);
    }
    return vfn;
  } else return validatorValue;
}

export class FormValidator {
  validators: FieldValidator;

  constructor(validators: FieldValidator) {
    this.validators = validators;
  }

  replaceFieldValidators(validators: FieldValidator) {
    this.validators = { ...this.validators, ...validators };
  }

  validateField(field: string, value: any | any[], returnOnlyFirst: boolean = true) {
    if (!this.validators[field]) {
      console.error(new Error(`No validator for field: ${field}`));
      return [];
    }
    const result = [];
    const validatorFieldKeys = Object.keys(this.validators[field]);
    for (const validatorKey of validatorFieldKeys) {
      const validatorFn = getValidatorFn(this.validators[field][validatorKey]);
      if (validatorFn(value)) {
        result.push(validatorKey);
        if (returnOnlyFirst) break;
      }
    }
    return result;
  }

  validateForm(values: { [key: string]: any }, returnOnlyFirst: boolean = true): FormValidationResult[] {
    const validatorFieldsKeys = Object.keys(this.validators);
    const result = [];
    // let firstResult;
    for (const validatorField of validatorFieldsKeys) {
      const fieldResult = this.validateField(validatorField, values[validatorField], returnOnlyFirst);
      if (fieldResult.length) {
        result.push({
          field: validatorField,
          errors: fieldResult,
        });
      }
    }
    return result;
  }

  static validateOnTheFly(validator: FieldErrorValidator, value: any,
                          returnOnlyFirst: boolean = true): [] {
    const validatorFieldKeys = Object.keys(validator);
    const result = [];
    for (const validatorKey of validatorFieldKeys) {
      const validatorFn = getValidatorFn(validator[validatorKey]);
      if (validatorFn(value)) {
        result.push(validatorKey);
        if (returnOnlyFirst) break;
      }
    }
    return result;
  };

  static buildErrorLabel(errors: string[] = [], dict: { [key: string]: string },
                         errorRender: (errors?: string[], errorLabels: { [key: string]: string }) => any) {
    return errorRender(errors, dict);
  }

  static flattenForm(form: { [key: string]: { value: any } }): any {
    Object.keys(form).reduce((acum: { [key: string]: any }, key: string) => ({
      ...acum,
      [key]: form[key].value,
    }), {});
  }

  static getFlatValueFromField(formField?: FormField, defaultValue?: any): any {
    if (!formField) return defaultValue;
    return formField.value;
  }
}
